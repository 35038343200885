class Tracker {

    static pixelEvent(code, data) {
        if (typeof fbq === 'undefined') {
            console.log('fbq is undefined');

            return;
        }

        console.log('%c fbq trackCustom: ', 'background: #000; color: #a78bfa;', code, data);

        fbq('trackCustom', code, data);
    }

    static pixelPageOpen(code) {
        this.pixelEvent(`pageview_${code}`);
    }

}

export default Tracker;
